class APP {
    constructor() {
        console.log('Document loaded');
        this.cacheDomElements();
        this.addEventListener();
        this.fetchMyoData();
    }

    cacheDomElements() {
         this.footerLinks = document.querySelectorAll('.footer-link');
         this.modals = document.querySelectorAll('.modal');
         this.myo = document.getElementById('myo');
    }

    addEventListener() {
        this.footerLinks.forEach((el) => {
            el.addEventListener('click', this.toggleModal.bind(this));
        });
        this.modals.forEach((modal) => {
            modal.querySelector('.modal__close').addEventListener('click', this.toggleModal.bind(this));
        });
    }

    toggleModal(e) {
        // const modal = document.getElementById('modal');
        const modalId = e.currentTarget.getAttribute('rel');

        // Close modals
        if(!modalId) {
            this.modals.forEach((modal) => {
                modal.classList.remove('is-active');
            });
            return;
        }

        // Open Modal
        this.modals.forEach((modal) => {
            if(modal.id === modalId) {
                modal.classList.add('is-active');
            }
        });
    }

    fetchMyoData() {
        const url = 'https://api.coingecko.com/api/v3/simple/price?ids=mycro-ico&vs_currencies=usd&include_24hr_vol=true&include_24hr_change=true';

        fetchData(url)
        .then(data => {
            if(!data) return;

            let price = data['mycro-ico'].usd;
            let percentage = data['mycro-ico'].usd_24h_change;
            
            price = Math.round((price + Number.EPSILON) * 100) / 100;
            percentage = Math.round((percentage + Number.EPSILON) * 100) / 100;

            this.myo.querySelector('.myo__price .value').innerHTML = price;
            this.myo.querySelector('.myo__percent .value').innerHTML = percentage;

        });

        async function fetchData(url) {
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                // body: JSON.stringify(data) // body data type must match "Content-Type" header
              });
              return response.json();
        }
    }
}

// Fire
document.addEventListener('DOMContentLoaded', (event) => {
    new APP();
});